<template>
  <validation-observer ref="validationObserver">
    <b-card-form>
      <div>
        <div>
          <b-form
            ref="form"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent="repeateAgain"
          >

            <!-- Row Loop -->
            <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
            >
              <b-col
                cols="12"
              >
                <b-row>
                  <!-- Item Name -->
                  <b-col
                    md="4"
                    xl="5"
                  >
                    <b-form-group
                      label="Title English"
                      label-for="item-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="'titleEn' + item.id"
                        rules="required"
                        name="Title English"
                      >
                        <b-form-input
                          v-model="item.titleEn"
                          :name="'titleEn'+item.id"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Title English"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Item Name -->
                  <b-col
                    md="4"
                    xl="5"
                    dir="rtl"
                  >
                    <b-form-group
                      label="Title Arabic"
                      label-for="item-name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :vid="'titleAr' + item.id"
                        rules="required"
                        name="itle Arabic"
                      >
                        <b-form-input
                          v-model="item.titleAr"
                          :name="'titleAr'+item.id"
                          type="text"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Title Arabic"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>
                  </b-col>

                  <!-- Remove Button -->
                  <b-col
                    lg="2"
                    md="3"
                    class="mb-50"
                  >
                    <b-button
                      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                      variant="outline-danger"
                      class="mt-0 mt-md-2"
                      @click="removeItem(index)"
                    >
                      <feather-icon
                        icon="XIcon"
                        class="mr-25"
                      />
                      <span>Delete</span>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row>
                  <!-- Item Name -->
                  <b-col
                    md="4"
                    xl="5"
                  >
                    <label for="textarea-default">Body English</label>
                    <validation-provider
                      #default="{ errors }"
                      :vid="'bodyEn' + item.id"
                      rules="required"
                      name="Body English"
                    >
                      <b-form-textarea
                        v-model="item.bodyEn"
                        :name="'bodyEn'+item.id"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Body English"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                  <!-- Item Name -->
                  <b-col
                    md="4"
                    xl="5"
                    dir="rtl"
                  >
                    <label for="textarea-default">Body Arabic</label>
                    <validation-provider
                      #default="{ errors }"
                      :vid="'bodyEn' + item.id"
                      rules="required"
                      name="Body Arabic"
                    >
                      <b-form-textarea
                        v-model="item.bodyAr"
                        :name="'bodyEn'+item.id"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Body Arabic"
                        rows="3"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>

                </b-row>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-row>
          </b-form>
        </div>
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="repeateAgain"
            >
              <feather-icon
                icon="PlusIcon"
                class="mr-25"
              />
              <span>Add New</span>
            </b-button>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="save(false)"
            >
              <span>Save</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card-form>
  </validation-observer>
</template>

<script>
import BCardForm from '@/components/b-card-form'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton, BFormTextarea,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import UUID from '@/libs/uuid'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCardForm,

  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      items: [],
      nextTodoId: 2,
      required,
    }
  },
  mounted() {
    this.$http.get('/AboutUs/sections')
      .then(response => {
        this.items = response.data
      })
  },

  methods: {
    repeateAgain() {
      this.items.push({
        id: UUID(),
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
    save(isPublish){
      const valid = this.$refs.validationObserver.validate().then(success => {
        if (success) {
          const data = { sections: this.items }
          this.$http.post('/AboutUs/sections', data).then(()=>{
                if(!isPublish)
                {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Save Successfully",
                      icon: 'CheckCircleIcon',
                      variant: 'success',
                    },
                  });
                }
              })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Check the feilds validations above to be able to save!',
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        }
        return success
      })
      return valid
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
