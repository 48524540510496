var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"validationObserver"},[_c('b-card-form',[_c('div',[_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({height: _vm.trHeight}),on:{"submit":function($event){$event.preventDefault();return _vm.repeateAgain.apply(null, arguments)}}},_vm._l((_vm.items),function(item,index){return _c('b-row',{key:item.id,ref:"row",refInFor:true,attrs:{"id":item.id}},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('b-form-group',{attrs:{"label":"Title English","label-for":"item-name"}},[_c('validation-provider',{attrs:{"vid":'titleEn' + item.id,"rules":"required","name":"Title English"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":'titleEn'+item.id,"type":"text","state":errors.length > 0 ? false:null,"placeholder":"Title English"},model:{value:(item.titleEn),callback:function ($$v) {_vm.$set(item, "titleEn", $$v)},expression:"item.titleEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('b-form-group',{attrs:{"label":"Title Arabic","label-for":"item-name"}},[_c('validation-provider',{attrs:{"vid":'titleAr' + item.id,"rules":"required","name":"itle Arabic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":'titleAr'+item.id,"type":"text","state":errors.length > 0 ? false:null,"placeholder":"Title Arabic"},model:{value:(item.titleAr),callback:function ($$v) {_vm.$set(item, "titleAr", $$v)},expression:"item.titleAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Delete")])],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"4","xl":"5"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Body English")]),_c('validation-provider',{attrs:{"vid":'bodyEn' + item.id,"rules":"required","name":"Body English"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"name":'bodyEn'+item.id,"state":errors.length > 0 ? false:null,"placeholder":"Body English","rows":"3"},model:{value:(item.bodyEn),callback:function ($$v) {_vm.$set(item, "bodyEn", $$v)},expression:"item.bodyEn"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4","xl":"5","dir":"rtl"}},[_c('label',{attrs:{"for":"textarea-default"}},[_vm._v("Body Arabic")]),_c('validation-provider',{attrs:{"vid":'bodyEn' + item.id,"rules":"required","name":"Body Arabic"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"name":'bodyEn'+item.id,"state":errors.length > 0 ? false:null,"placeholder":"Body Arabic","rows":"3"},model:{value:(item.bodyAr),callback:function ($$v) {_vm.$set(item, "bodyAr", $$v)},expression:"item.bodyAr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)],1),_c('b-row',[_c('b-col',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeateAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1),_c('b-col',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":function($event){return _vm.save(false)}}},[_c('span',[_vm._v("Save")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }