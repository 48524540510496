<template>
  <b-tabs>
    <b-tab active>
      <template #title>
        <feather-icon icon="HomeIcon" />
        <span>Title Section</span>
      </template>

      <about-us-main ref="main" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="ToolIcon" />
        <span>Body Section</span>
      </template>

      <about-us-sections ref="sections" />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="EyeOffIcon" />
        <span>Team Members</span>
      </template>

      <about-us-team-members ref="teammembers" />
    </b-tab>

    <!-- New Tab Button (Using tabs-end slot) -->
    <template #tabs-end>
      <div class="ml-auto">
        <b-row>
          <b-col>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              right
              @click.prevent="preview"
            >
              Preview
            </b-button>
          </b-col>
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              right
              @click.prevent="publish"
            >
              Publish
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BButton, BCol, BRow,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import AboutUsMain from './AboutUsMain.vue';
import AboutUsSections from './AboutUsSections.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import AboutUsTeamMembers from './AboutUsTeamMembers.vue';

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCol,
    BRow,
    AboutUsMain,
    AboutUsSections,
    AboutUsTeamMembers,
  },
  directives: {
    Ripple,
  },
  methods: {
    preview() {
      Promise.all([
        this.$refs.main.save(true),
        this.$refs.sections.save(true),
        this.$refs.teammembers.save(true)]).then(values => {
        if (values.every(element => element === true)) {
          window.open(`${process.env.VUE_APP_PREVIEW_URL}about-us?preview=true`)
        }
      });
    },
    async publish() {
      await Promise.all([
        this.$refs.main.save(true),
        this.$refs.sections.save(true),
        this.$refs.teammembers.save(true)])
      await this.$http.post('/AboutUs/publish');

      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Published Successfully',
          icon: 'CheckCircleIcon',
          variant: 'success',
        },
      });
    },
  },
};
</script>
